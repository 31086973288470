// Sentry must be first.
import * as Sentry from "@sentry/react";

import { buildTracingUserIdForInterviewApp } from "app-types/api/tracing";
import { escapeRegExp } from "lodash";
import { selectInterview } from "../features/transcriptFragments/transcriptFragmentsSelectors";
import type { RootState } from "./store";

export interface SentryManager {
  onStoreChange: (getState: () => RootState) => void;
}

const nullSentryManager: SentryManager = {
  onStoreChange: () => {},
};

export function startSentry(): SentryManager {
  // Enable Sentry only in production, unless VITE_SENTRY_ENABLED is set.
  const isSentryEnabled =
    import.meta.env.PROD || import.meta.env.VITE_SENTRY_ENABLED;

  if (!isSentryEnabled) {
    return nullSentryManager;
  }

  // TODO: Use Posthog <> Sentry integration for error reporting.
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_INTERVIEW_DSN,
    // TODO: Set up component tracing and Redux integrations.
    integrations: [Sentry.browserTracingIntegration()],
    environment: import.meta.env.PROD ? "production" : "development",
    // Capture 100% of the transactions for tracing
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      /^\//,
      new RegExp(`^${escapeRegExp(import.meta.env.VITE_API_BASE_URL)}`, "i"),
    ],
  });

  const onContactIdChange = (contactId: string | undefined) => {
    Sentry.setUser(
      contactId
        ? {
            id: buildTracingUserIdForInterviewApp({ contactId }),
          }
        : null,
    );
  };

  const onInterviewIdChange = (interviewId: string | undefined) => {
    Sentry.setTag("interview_id", interviewId);
  };

  let contactId: string | undefined = undefined;
  let interviewId: string | undefined = undefined;

  return {
    onStoreChange: (getState) => {
      const state = getState();
      const interview = selectInterview(state);

      const newContactId = interview?.contact?.id;
      if (newContactId !== contactId) {
        contactId = newContactId;
        onContactIdChange(contactId);
      }

      const newInterviewId = interview?.id;
      if (newInterviewId !== interviewId) {
        interviewId = newInterviewId;
        onInterviewIdChange(interviewId);
      }
    },
  };
}

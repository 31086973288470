import { buildTracingUserIdForInterviewApp } from "app-types/api/tracing";
import posthog from "posthog-js";
import { selectProjectLinkState } from "../features/projectLink/projectLinkSelectors";
import { selectInterview } from "../features/transcriptFragments/transcriptFragmentsSelectors";
import type { RootState } from "./store";

export interface PostHogManager {
  onStoreChange: (getState: () => RootState) => void;
}

const nullPostHogManager: PostHogManager = {
  onStoreChange: () => {},
};

export function startPostHog(): PostHogManager {
  // Enable PostHog only in production, unless VITE_POSTHOG_ENABLED is set.
  const isPostHogEnabled =
    import.meta.env.PROD || import.meta.env.VITE_POSTHOG_ENABLED;

  if (!isPostHogEnabled) {
    return nullPostHogManager;
  }

  const onHasSensitiveDataChange = (hasSensitiveData: boolean | undefined) => {
    posthog.set_config(computePostHogTrackingConfig(hasSensitiveData));
  };

  const onContactIdChange = (contactId: string | undefined) => {
    if (contactId) {
      posthog.identify(buildTracingUserIdForInterviewApp({ contactId }));
    } else {
      posthog.reset();
    }
  };

  const onInterviewIdChange = (interviewId: string | undefined) => {
    if (interviewId) {
      posthog.register_for_session({
        interview_id: interviewId,
      });
    } else {
      posthog.unregister_for_session("interview_id");
    }
  };

  let hasSensitiveData: boolean | undefined = undefined;
  let contactId: string | undefined = undefined;
  let interviewId: string | undefined = undefined;

  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: "https://us.i.posthog.com",
    ...computePostHogTrackingConfig(hasSensitiveData),
  });

  return {
    onStoreChange: (getState) => {
      const state = getState();
      const projectLink = selectProjectLinkState(state);
      const interview = selectInterview(state);

      const newHasSensitiveData = projectLink?.project?.has_sensitive_data;
      if (newHasSensitiveData !== hasSensitiveData) {
        hasSensitiveData = newHasSensitiveData;
        onHasSensitiveDataChange(hasSensitiveData);
      }

      const newContactId = interview?.contact?.id;
      if (newContactId !== contactId) {
        contactId = newContactId;
        onContactIdChange(contactId);
      }

      const newInterviewId = interview?.id;
      if (newInterviewId !== interviewId) {
        interviewId = newInterviewId;
        onInterviewIdChange(interviewId);
      }
    },
  };
}

function computePostHogTrackingConfig(hasSensitiveData: boolean | undefined) {
  // Disable auto-recording and session recording until we know this is NOT a sensitive data company.
  const shouldRecord = hasSensitiveData === false;

  return {
    autocapture: shouldRecord,
    disable_session_recording: !shouldRecord,
  };
}

// Sentry must be first.
import { startSentry } from "./app/sentry";

import { startPostHog } from "./app/postHog";

declare global {
  function onAppStartError(): void;
}

async function startApp() {
  // Initialize Sentry as early as possible.
  const sentryManager = startSentry();

  // Initialize PostHog as early as possible.
  const postHogManager = startPostHog();

  // Load the data and view layers of the app.
  const [{ createStore }, { renderApp }] = await Promise.all([
    import("./app/store"),
    import("./app/view"),
  ]);

  // Initialize the store.
  const store = createStore();
  store.subscribe(() => {
    sentryManager.onStoreChange(store.getState);
    postHogManager.onStoreChange(store.getState);
  });

  // Render the app.
  renderApp(store);
}

startApp().catch(onAppStartError);
